import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
 Box,
 Stack,
 Modal,
 Typography,
 CircularProgress,
 IconButton,
 Autocomplete,
 TextField,
 Paper,
 Button,
 InputAdornment,
 OutlinedInput,
 Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Calendar, momentLocalizer, Views, Navigate } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import { Search } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
 getMachines,
 getServiceTicketsByStatusList,
 notifyVendor,
 readAsyncStorageValues,
 rescheduleServiceTickets,
 updateServiceTicket,
 getServiceTicketsByStatusListForSupervisor
} from "features/service/service.slice";
import { getAllUsers, getAllVendors } from "features/appUsers/appUsers.slice";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";


const GREEN = "#018749";
const RED = "#A52A2A";
const BLUE = "#0066b2";
const ORANGE = "#E49B0F";


const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);


const Scheduling = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const moreModalRef = useRef(null);


 const { appConstants, serviceTicketsByStatusList, machinesData, serviceTicketsStatusForSupervisor } = useSelector((state) => state.service);
 const { allUsers, allVendorsList } = useSelector((state) => state.appUsers);
 const { mode } = useSelector((state) => state.globalState);


 const [events, setEvents] = useState([]);
 const [selectedDays, setSelectedDays] = React.useState(null);


 const [calendarView, setCalendarView] = useState(Views.MONTH);
 const [loading, setLoading] = useState(true);
 const [modalOpen, setModalOpen] = useState(false);
 const [moreModalOpen, setMoreModalOpen] = useState(false);
 const [isButtonDisabled, setIsButtonDisabled] = useState(false);


 const [moreEvents, setMoreEvents] = useState(null);
 const [moreClickXY, setMoreClickXY] = useState({
   x: "50%",
   y: "50%",
 });
 const [selectedEvent, setSelectedEvent] = useState(null);
 const [technicianList, setTechicianList] = useState([]);
 const [ticketCategoryList, setTicketCategoryList] = useState([]);
 const [ticketStatusList, setTicketStatusList] = useState([]);
 const [vendorList, setVendorList] = useState([]);
 const [selectedVendors, setSelectedVendors] = useState([]);
 const [searchValue, setSearchValue] = useState("");
 const [selectedTechnician, setSelectedTechnician] = useState({
   label: "Select technician",
   value: null,
 });
 const [selectedTicketCategory, setSelectedTicketCategory] = useState({
   label: "Select ticket category",
   value: null,
 });
 const [selectedTicketStatus, setSelectedTicketStatus] = useState({
   label: "Select ticket status",
   value: null,
 });
 const [selectedVendor, setSelectedVendor] = useState({
   label: "Select vendor",
   value: null,
 });


 const styleCallback = (event) => {
   return { style: { backgroundColor: event.backgroundColor, color: "white" } };
 };


 useEffect(() => {
   dispatch(readAsyncStorageValues());
   dispatch(getMachines());
   dispatch(getAllUsers());
   dispatch(getAllVendors());
 }, [dispatch]);

 useEffect(() => {
   if (Object.keys(appConstants).length) {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; 
    const year = currentDate.getFullYear();  
     dispatch(
      getServiceTicketsByStatusListForSupervisor({
         statusList: [
           appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
           appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
           appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
           appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
           appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
           appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED,
           appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED,
           appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED,
         ],
         month,
         year
       })
     );
     const technicians = appConstants.appUsers
       .filter((technician) => technician.userRole === appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN)
       .map((technician) => {
         return {
           label: technician.nameEmpno,
           value: technician.userId,
         };
       });
     technicians.unshift({
       label: "Select technician",
       value: null,
     });
     setTechicianList(technicians);


     const ticketCategories = Object.values(appConstants.allTypes.SERVICE_CATEGORY)
       .sort((a, b) => (a > b ? 1 : -1))
       .map((ticketCategory) => {
         return {
           label: ticketCategory,
           value: ticketCategory,
         };
       });
     ticketCategories.unshift({
       label: "Select ticket category",
       value: null,
     });
     setTicketCategoryList(ticketCategories);


     const ticketStatuses = Object.values(appConstants.allTypes.SERVICE_TICKET_STATUS)
       .sort((a, b) => (a > b ? 1 : -1))
       .map((ticketStatus) => {
         return {
           label: ticketStatus,
           value: ticketStatus,
         };
       })
       .filter((item) => {
         const {
           ASSIGNED,
           ASSIGN_SERVICE_ENGINEER,
           VENDOR_SUPPORT_REQUIRED,
           SUPERVISOR_APPROVED,
           UNDER_OBSERVATION,
           COMPLETED,
           SPARES_REQUIRED,
           SPARES_RECOMMENDED,
           SPARES_REQUIRED_PENDING,
           SPARES_RECOMMENDED_PENDING,
           VENDOR_SUPPORT_PENDING,
           ON_HOLD,
           CANCELLED,
         } = appConstants.allTypes.SERVICE_TICKET_STATUS;


         return [
           ASSIGNED,
           ASSIGN_SERVICE_ENGINEER,
           VENDOR_SUPPORT_REQUIRED,
           SUPERVISOR_APPROVED,
           UNDER_OBSERVATION,
           COMPLETED,
           SPARES_REQUIRED,
           SPARES_RECOMMENDED,
           SPARES_REQUIRED_PENDING,
           SPARES_RECOMMENDED_PENDING,
           VENDOR_SUPPORT_PENDING,
           ON_HOLD,
           CANCELLED,
         ].includes(item.value);
       });
     ticketStatuses.unshift({
       label: "Select ticket status",
       value: null,
     });
     setTicketStatusList(ticketStatuses);
   }
 }, [dispatch, appConstants]);


 useEffect(() => {
   if (allVendorsList.data?.length) {
     const vendors = [...allVendorsList.data]
       .sort((a, b) => (a.customerName > b.customerName ? 1 : -1))
       .map((vendor) => {
         return {
           label: vendor.customerName,
           value: vendor.id,
         };
       });
     setVendorList(vendors);
   }
 }, [allVendorsList.data]);


 useEffect(() => {
   if (serviceTicketsStatusForSupervisor && appConstants && machinesData && allUsers) {
     const filteredTickets = !selectedTicketStatus?.value
       ? serviceTicketsStatusForSupervisor.data.filter(
           (ticket) =>
             ![
               appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
             ].includes(ticket.status)
         )
       : serviceTicketsStatusForSupervisor;
     const events = filteredTickets
       .map((ticket) => {
         const machine = machinesData.find((machine) => machine.id === ticket?.machine?.id);
         const assigner = allUsers.find((user) => user.userId === ticket.assigner.id);
         let assignees = ticket.assignees;
         if (assignees?.length) {
           assignees = ticket.assignees.reduce((assignees, item) => {
             const assigneeInfo = allUsers.find((user) => user.userId === item.id);
             if (assigneeInfo) assignees.push(assigneeInfo);
             return assignees;
           }, []);
         }
         // if (ticket.vendor?.id) console.log(ticket.vendor?.id);
         // if (ticket.vendor?.id) console.log(ticket.vendor.id !== selectedVendor.value);
         const assigneeIds = ticket.assignees?.map(({ id }) => id) || [];
         const abbreviatedTitle = `${machine?.maintenanceCode || ""} (${ticket.id}) ${
           appConstants.allTypes.ABBREVIATIONS[ticket.ticketCategory]
         } | ${ticket.status} | ${machine?.manufacturer || ""} ${machine?.model || ""} [${machine?.serialNumber || ""}]`;
         const fullTitleForSearch = `${machine?.maintenanceCode || ""} ${ticket.id} ${
           appConstants.allTypes.ABBREVIATIONS[ticket.ticketCategory]
         } ${ticket.ticketCategory} ${machine?.manufacturer || ""} ${machine?.model || ""} ${machine?.serialNumber || ""} ${
           ticket.status
         }`;
         const vendorFilterValues = selectedVendors.map((item) => item.value);
         if (
           !!(selectedTechnician.value && !assigneeIds.includes(selectedTechnician.value)) ||
           !!(selectedTicketCategory.value && ticket.ticketCategory !== selectedTicketCategory.value) ||
           !!(selectedTicketStatus.value && ticket.status !== selectedTicketStatus.value) ||
           !!(vendorFilterValues && vendorFilterValues.length && !vendorFilterValues.includes(ticket.vendor?.id)) ||
           !!(fullTitleForSearch.toLowerCase().search(searchValue.toLowerCase()) === -1)
         )
           return;
         else {
           const assignTechincian = ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER;
           const overdue =
             ticket.dueDate &&
             new Date(ticket.dueDate) < new Date() &&
             ticket.status !== appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED;
           const backgroundColor = assignTechincian ? ORANGE : overdue ? RED : BLUE;


           // const utcDate = new Date(ticket.dueDate || ticket.scheduledDate);
           // Calculate the timezone offset in minutes
           // const timezoneOffset = utcDate.getTimezoneOffset();
           // Adjust the date by adding the offset to the timestamp
           // const adjustedDate = new Date(utcDate.getTime() + timezoneOffset * 60 * 1000);
           const date = new Date(ticket.dueDate || ticket.scheduledDate);
           date.setHours(0, 0, 0, 0);
           return {
             title: abbreviatedTitle,
             fullTitleForSearch,
             start: date,
             end: date,
             ticket: { ...ticket, assigner, assignees },
             machine,
             backgroundColor,
           };
         }
       })
       .filter((ticket) => ticket); // to remove undefined from the array
     setEvents(events);
     setLoading(false);
   }
 }, [
   serviceTicketsStatusForSupervisor,
   appConstants,
   machinesData,
   allUsers,
   selectedTechnician?.value,
   selectedTicketCategory?.value,
   selectedTicketStatus?.value,
   selectedVendors,
   searchValue,
 ]);


 const handleSelectEvent = (event) => {
   setSelectedEvent(event);
   setModalOpen(true);
 };


 const handleDaysChange = (event, newValue) => {
   setSelectedDays(newValue);
 };


 const onEventDrop = (data) => {
   if (data?.event?.ticket?.id) {
     const { SUPERVISOR_APPROVED, COMPLETED } = appConstants.allTypes.SERVICE_TICKET_STATUS;
     const dateCreated = new Date(data.event.ticket.dateCreated);
     const newDueDate = new Date(data.start);
     // Set both dates to midnight
     dateCreated.setHours(0, 0, 0, 0);
     newDueDate.setHours(0, 0, 0, 0);


     if (newDueDate < dateCreated) return toast.error("Due date should not be earlier than the created date.");
     if ([SUPERVISOR_APPROVED, COMPLETED].includes(data.event.ticket.status))
       return toast.error(
         `The due date is not allowed to be changed for tickets with the status ${SUPERVISOR_APPROVED} and ${COMPLETED}.`
       );


     const updateObject = {
       serviceTicketId: data.event.ticket.id,
       dateCreated: data.event.ticket.dateCreated,
       status: data.event.ticket.status,
       ticketCategory: data.event.ticket.ticketCategory,
       scheduledDate: moment(data.event.ticket.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
       dueDate: moment(data.start).format("YYYY-MM-DDTHH:mm:ssZ"),
       machineId: data.event.ticket.machine.id,
       assignees: data.event.ticket.assignees.length ? data.event.ticket.assignees.map((a) => a.userId) : [],
       priority: data.event.ticket.priority,
       problemCategory: data.event.ticket.problemCategory,
       vendor: data.event.ticket.vendor?.id || null,
       description: data.event.ticket.description,
     };
     dispatch(updateServiceTicket(updateObject)).then(() =>
       dispatch(
        getServiceTicketsByStatusListForSupervisor({
           statusList: [
             appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
             appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
             appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
             appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
             appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
             appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED,
             appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED,
             appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED,
           ],
         })
       )
     );


     const modifiedEvents = events.map((event) => {
       if (event.ticket.id === data.event.ticket.id) {
         const overdue = updateObject.dueDate && new Date(updateObject.dueDate) < new Date();
         const backgroundColor = overdue ? RED : BLUE;
         return {
           ...event,
           start: data.start,
           end: data.end,
           ticket: { ...event.ticket, dueDate: updateObject.dueDate },
           backgroundColor,
         };
       } else return event;
     });
     setEvents(modifiedEvents);
   }
 };


 const handleCloseModal = () => setModalOpen(false);


 const onTechnicianChange = (event, newValue) => {
   if (!newValue)
     setSelectedTechnician({
       label: "",
       value: null,
     });
   else setSelectedTechnician(newValue);
 };


 const onTicketCategoryChange = (event, newValue) => {
   if (!newValue)
     setSelectedTicketCategory({
       label: "",
       value: null,
     });
   else setSelectedTicketCategory(newValue);
 };


 const onTicketStatusChange = (event, newValue) => {
   if (!newValue)
     setSelectedTicketStatus({
       label: "",
       value: null,
     });
   else setSelectedTicketStatus(newValue);
 };


 const onVendorChange = (event, newValues) => {
   if (!newValues) {
     setSelectedVendors([]);
   } else {
     setSelectedVendors(newValues);
   }
 };


 const handleSearchChange = (event) => {
   setSearchValue(event.target.value);
 };


 const TicketDetailsItem = ({ item, value }) => (
   <Box>
     <Typography display="inline" sx={{ fontWeight: "bold" }}>
       {item}
     </Typography>
     <Typography display="inline">
       {value.length > 100 ? value.slice(0, 120) + "..." : value}
       {value.length > 100 ? (
         <Typography display="inline" variant="caption">
           {"(click VIEW/EDIT TICKET to see full details)"}
         </Typography>
       ) : null}
     </Typography>
   </Box>
 );


 const ShowMore = (total) => (
   <Button
     variant="outlined"
     size="small"
     sx={{ cursor: "pointer" }}
     onClick={(e) => {
       setMoreClickXY({
         x: e.clientX,
         y: e.clientY,
       });
       // e.stopPropagation();
       // e.preventDefault();
     }}
   >
     {`+${total} more tickets`}
   </Button>
 );


 const onOnClickShowMore = (events) => {
   setMoreEvents(events);
   setMoreModalOpen(true);
 };


 const handleNavigate = (newDate, view, action) => {
   switch (action) {
     case Navigate.TODAY:
       return moment().toDate();
     case Navigate.PREVIOUS:
       return moment(newDate).subtract(1, view).toDate();
     case Navigate.NEXT:
       return moment(newDate).add(1, view).toDate();
     default:
       return newDate;
   }
 };


 const CustomToolbar = (toolbar) => {
   return (
     <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={1}>
       <Stack flexDirection={"row"} gap={0.2}>
         <Button autoCapitalize="none" variant="contained" onClick={() => toolbar.onNavigate(Navigate.TODAY)}>
           Today
         </Button>
         <Button autoCapitalize="none" variant="contained" onClick={() => toolbar.onNavigate(Navigate.PREVIOUS)}>
           Back
         </Button>
         <Button autoCapitalize="none" variant="contained" onClick={() => toolbar.onNavigate(Navigate.NEXT)}>
           Next
         </Button>
       </Stack>
       <Typography variant="h5">{toolbar.label}</Typography>
       <Stack flexDirection={"row"} gap={0.2}>
         <Button autoCapitalize="none" variant="contained" onClick={() => setCalendarView(Views.MONTH)}>
           {Views.MONTH}
         </Button>
         <Button autoCapitalize="none" variant="contained" onClick={() => setCalendarView(Views.WEEK)}>
           {Views.WEEK}
         </Button>
         <Button autoCapitalize="none" variant="contained" onClick={() => setCalendarView(Views.DAY)}>
           {Views.DAY}
         </Button>
       </Stack>
     </Stack>
   );
 };
 const calendarStyle = (date) => {
   let currentDate = `${new Date().getDate()} ${new Date().getMonth() + 1} ${new Date().getFullYear()}`;
   let allDate = `${date.getDate()} ${date.getMonth() + 1} ${date.getFullYear()}`;


   if (allDate === currentDate && mode === "dark")
     return {
       style: {
         backgroundColor: "#737373",
         border: "1px solid gray",
         margin: 0,
         padding: 0,
       },
     };
 };


 const rescheduleServiceTicketsFunction = () => {
   if (selectedDays) {
     dispatch(rescheduleServiceTickets(parseInt(selectedDays)))
       .then((response) => {
         setLoading(true);
         dispatch(
          getServiceTicketsByStatusListForSupervisor({
             statusList: [
               appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
               appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
               appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED,
               appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED,
             ],
           })
         );
         toast.success(response.payload.data.message, { autoClose: 1500 });
         setSelectedDays(null);
       })
       .catch((error) => {
         console.error("Error while rescheduling service tickets:", error);
         toast.error("An error occurred while rescheduling service tickets. Please try again.");
       });
   } else {
     toast.warning("Please select due date");
   }
 };
 const bulkNotify = async () => {
   if (selectedVendors.length === 0) {
     toast.error("Please select vendor(s) to notify");
     return;
   }


   setIsButtonDisabled(true);


   try {
     const vendorValues = selectedVendors.map((item) => item.value);
     const response = await dispatch(notifyVendor({ vendorIds: vendorValues }));
     if (response) {
       toast.success(response.payload.data.message, { autoClose: 2000 });
     }
   } catch (error) {
     console.error("Error while notifying vendors:", error);
     toast.error("An error occurred while notifying vendors. Please try again.");
   } finally {
     setIsButtonDisabled(false);
   }
 };
 if (loading)
   return (
     <Stack alignItems={"center"} sx={{ mt: 10 }}>
       <CircularProgress />
     </Stack>
   );
 return (
   <div>
     <Stack sx={{ ml: 2, mt: 1 }}>
       <Header titles={["Scheduling Calendar"]} />
     </Stack>
     <Paper sx={{ marginX: 2, paddingY: 3, marginY: 2 }}>
       <Stack alignItems={"center"}>
         <Grid container spacing={2} sx={{ ml: 5, marginRight: 2 }}>
           <Grid item xs={12} sm={6} md={3}>
             <OutlinedInput
               value={searchValue}
               onChange={(event) => handleSearchChange(event)}
               placeholder="Search..."
               endAdornment={
                 <InputAdornment position="end">
                   <IconButton edge="end">
                     <Search />
                   </IconButton>
                 </InputAdornment>
               }
               sx={{ width: "100%", height: 35 }}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Autocomplete
               disablePortal
               size="small"
               options={technicianList}
               onChange={onTechnicianChange}
               value={selectedTechnician}
               sx={{ width: "100%" }}
               renderInput={(params) => <TextField {...params} label="Filter by ticket assignee" />}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Autocomplete
               disablePortal
               size="small"
               options={ticketCategoryList}
               onChange={onTicketCategoryChange}
               value={selectedTicketCategory}
               sx={{ width: "100%" }}
               renderInput={(params) => <TextField {...params} label="Filter by ticket category" />}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={2.7}>
             <Autocomplete
               disablePortal
               size="small"
               options={ticketStatusList}
               onChange={onTicketStatusChange}
               value={selectedTicketStatus}
               sx={{ width: "100%" }}
               renderInput={(params) => <TextField {...params} label="Filter by ticket status" />}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Autocomplete
               disablePortal
               size="small"
               options={Array.from({ length: 60 }, (_, index) => index + 1)}
               onChange={handleDaysChange}
               value={selectedDays}
               sx={{ width: "100%" }}
               renderInput={(params) => <TextField {...params} label="Reschedule overdue" />}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Button autoCapitalize="none" variant="contained" onClick={rescheduleServiceTicketsFunction}>
               Reschedule
             </Button>
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Autocomplete
               disablePortal
               size="small"
               multiple // Enable multiple selection
               options={vendorList}
               onChange={onVendorChange}
               value={selectedVendors}
               sx={{ width: "100%" }}
               renderInput={(params) => <TextField {...params} label="Filter by vendor" />}
             />
           </Grid>
           <Grid item xs={12} sm={6} md={3}>
             <Button autoCapitalize="none" variant="contained" onClick={bulkNotify} disabled={isButtonDisabled}>
               Notify vendor
             </Button>
           </Grid>
         </Grid>


         <Box sx={{ mt: 4, width: "97%" }}>
           <DndProvider backend={HTML5Backend}>
             <DragAndDropCalendar
               selectable
               resizable={false}
               localizer={localizer}
               events={events}
               startAccessor="start"
               endAccessor="end"
               style={{
                 height: 550,
                 width: "100%",
                 backgroundColor: "background.paper",
               }}
               onSelectEvent={handleSelectEvent}
               onEventDrop={onEventDrop}
               eventPropGetter={styleCallback}
               onShowMore={onOnClickShowMore}
               doShowMoreDrillDown={false}
               messages={{
                 showMore: ShowMore,
               }}
               components={{
                 toolbar: CustomToolbar,
               }}
               dayPropGetter={calendarStyle}
               defaultView={calendarView}
               view={calendarView}
               onNavigate={handleNavigate}
             />
           </DndProvider>
           <Stack flexDirection={"row"} mt={1} gap={1}>
             <Stack flexDirection={"row"} mt={1}>
               <CircleIcon sx={{ color: ORANGE }} />
               <Typography>Assign Technician</Typography>
             </Stack>
             <Stack flexDirection={"row"} mt={1}>
               <CircleIcon sx={{ color: RED }} />
               <Typography>Overdue</Typography>
             </Stack>
             <Stack flexDirection={"row"} mt={1}>
               <CircleIcon sx={{ color: BLUE }} />
               <Typography>Others</Typography>
             </Stack>
           </Stack>
         </Box>
         <Modal open={moreModalOpen} onClose={() => setMoreModalOpen(false)} ref={moreModalRef}>
           {moreClickXY && moreClickXY.x && moreClickXY?.y ? (
             <Box
               sx={{
                 position: "absolute",
                 top: moreClickXY.y,
                 left: moreClickXY.x,
                 transform: "translate(-50%, -50%)",
                 width: 300,
                 maxHeight: "40%",
                 overflow: "scroll",
                 overflowY: "scroll",
                 bgcolor: "background.paper",
                 border: "2px solid #0000030",
                 p: "4px",
                 borderRadius: "5px",
               }}
             >
               <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                 {moreEvents && moreEvents.length ? (
                   <Typography variant="h5" align="center" mb="1px">
                     {moment(moreEvents[0].start).format("DD MMM YYYY")}
                   </Typography>
                 ) : null}
                 {/* <Box sx={{ width: "100%", textAlign: "center" }}> */}
                 <IconButton onClick={() => setMoreModalOpen(false)}>
                   <CloseIcon />
                 </IconButton>
                 {/* </Box> */}
               </Stack>


               {moreEvents?.map((event) => (
                 <Box
                   component={"button"}
                   sx={{
                     lineHeight: 1.5,
                     backgroundColor: event.backgroundColor,
                     color: "white",
                     padding: "2px 5px",
                     borderRadius: "5px",
                     mb: "2px",
                     border: "none",
                     textAlign: "left",
                   }}
                   onClick={() => handleSelectEvent(event)}
                 >
                   {event.title}
                 </Box>
               ))}
             </Box>
           ) : null}
         </Modal>
         <Modal open={modalOpen} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
           <Box
             sx={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               width: 400,
               bgcolor: "background.paper",
               border: "2px solid #000",
               boxShadow: 24,
               p: 3,
             }}
           >
             <Stack flexDirection={"row"} alignItems={"baseline"} justifyContent={"space-between"}>
               <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
                 Ticket Details
               </Typography>
               <IconButton onClick={handleCloseModal}>
                 <CloseIcon />
               </IconButton>
             </Stack>
             {selectedEvent ? (
               <>
                 <TicketDetailsItem item="Ticket ID: " value={selectedEvent.ticket.id} />
                 <TicketDetailsItem item="Scheduled date: " value={moment(selectedEvent.ticket.scheduledDate).format("DD MMM YY")} />
                 <TicketDetailsItem
                   item="Due date: "
                   value={selectedEvent.ticket.dueDate ? moment(selectedEvent.ticket.dueDate).format("DD MMM YY") : "N.A"}
                 />
                 <TicketDetailsItem item="Category: " value={selectedEvent.ticket.ticketCategory} />
                 <TicketDetailsItem item="Maintenance code: " value={selectedEvent.machine?.maintenanceCode || "N.A"} />
                 <TicketDetailsItem
                   item="Machine: "
                   value={`${selectedEvent?.machine?.manufacturer || "N.A"} ${selectedEvent?.machine?.model || "N.A"} [${
                     selectedEvent?.machine?.serialNumber || "N.A"
                   }]`}
                 />
                 <TicketDetailsItem item="Description: " value={selectedEvent?.ticket?.description || ""} />
                 <TicketDetailsItem item="Status: " value={selectedEvent?.ticket?.status || ""} />
                 <TicketDetailsItem item="Created date: " value={moment(selectedEvent.ticket.dateCreated).format("DD MMM YY")} />
                 <TicketDetailsItem
                   item="Created by: "
                   value={`${selectedEvent.ticket.assigner.name} (${selectedEvent?.ticket?.assigner?.userName || ""})`}
                 />
                 <TicketDetailsItem item="Assignees: " value={selectedEvent.ticket.assignees.length ? "" : "No assignees"} />
                 {selectedEvent.ticket.assignees.length
                   ? selectedEvent.ticket.assignees.map((assignee) => <Typography>{`${assignee.name} (${assignee.userName})`}</Typography>)
                   : null}
                 <Button
                   variant="contained"
                   sx={{ mt: 2 }}
                   onClick={() => navigate(`/${Links.SERVICE_TICKET}/` + selectedEvent.ticket.id)}
                 >
                   View / Edit Ticket
                 </Button>
               </>
             ) : (
               <Typography variant="h6">Select an event from calendar to see more information here</Typography>
             )}
           </Box>
         </Modal>
       </Stack>
     </Paper>
   </div>
 );
};


export default Scheduling;



