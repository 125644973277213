import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "./service";
import axios from "axios";

const initialState = {
  isLoading: false,
  isUploading: false,
  isError: false,
  message: "",
  serviceTicketById: {},
  serviceTicketsByStatusListAndDateCreatedInDays: null,
  serviceTicketsByStatusList: null,
  serviceTicketsStatusByTech: {
    data: [],
    isLoading: false,
    error: null,
  },
  serviceTicketsByDepartment: [],
  timespentByServicetTicket: [],
  serviceTicketsByUserId: {
    data: [],
    isLoading: false,
    error: null,
  },
  engineerReports: [],
  data: [],
  hoursDataByDays: [],
  machinesData: [],
  serviceTicketMachine: [],
  appConstants: {},
  machineDepartments: [],
  spareParts: [],
  machinesNotWorking: [],
  activeMachinesByStatus: [],
  serviceTicketMessagesByMachineId: [],
  overdueServiceTickets: {
    data: null,
    isLoading: false,
    error: null,
  },
  outOfServiceMachinesByCategory: [],
  completedReportPdf: [],
  error: null,
  serviceTicketMessages: [],
  serviceTimeLogs: [],
  weeklyBreakdown: [],
  monthlyBreakdown: [],
  technicianLoggedTime: {},
  createTicket: {
    data: {},
    isLoading: false,
    error: null,
  },
  allTemplates: {
    data: [],
    isLoading: false,
    error: null,
  },
  serviceTicketSpareParts: {
    data: [],
    isLoading: false,
    error: null,
  },
  templateById: {
    data: {},
    isLoading: false,
    error: null,
  },
  monthlyBreakdownHoursPercent: {
    data: [],
    isLoading: false,
    error: null,
  },
  weeklyBreakdownHoursPercent: {
    data: [],
    isLoading: false,
    error: null,
  },
  allServiceTickets: {
    data: [],
    isLoading: false,
    error: null,
  },
  serviceTicketsStatusForSupervisor: {
    data: [],
    isLoading: false,
    error: null,
  },
};

export const getServiceTicketsStatusByTechId = createAsyncThunk("serviceapi/getServiceTicketsStatusByTechId", async (payload, thunkAPI) => {
  try {
    return await Service.getServiceTicketsStatusByTechId(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getSparesPartsFromServiceTicket = createAsyncThunk(
  "serviceapi/getSparesPartsFromServiceTicket?id=",
  async (payload, thunkAPI) => {
    try {
      return await Service.getSparesPartsFromServiceTicket(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const issueSparesPartsForServiceTicket = createAsyncThunk(
  "serviceapi/getSparesPartsFromServiceTicket",
  async (payload, thunkAPI) => {
    try {
      return await Service.issueSparesPartsForServiceTicket(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getServiceTicketById = createAsyncThunk("service/getServiceTicketById", async (payload, thunkAPI) => {
  try {
    return await Service.getServiceTicketById(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const completeServiceTicket = createAsyncThunk("serviceTickets/completeTicket", async (payload, thunkAPI) => {
  try {
    return await Service.completeServiceTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createMachineLocation = createAsyncThunk("service/createMachineLocation", async (payload, thunkAPI) => {
  try {
    return await Service.createMachineLocation(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateMachineLocation = createAsyncThunk("service/updateMachineLocation", async (payload, thunkAPI) => {
  try {
    return await Service.updateMachineLocation(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createServiceTicket = createAsyncThunk("service/createServiceTicket", async (payload, thunkAPI) => {
  try {
    return await Service.createServiceTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateServiceTicket = createAsyncThunk("service/updateServiceTicket", async (payload, thunkAPI) => {
  try {
    return await Service.updateServiceTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const approveServiceTicket = createAsyncThunk("service/approveServiceTicket", async (payload, thunkAPI) => {
  try {
    return await Service.approveServiceTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getServiceTicketsByStatusListAndDateCreatedInDaysV1 = createAsyncThunk(
  "service/getServiceTicketsByStatusListAndDateCreatedInDaysV1",
  async (payload, { rejectWithValue }) => {
    try {
      return await Service.getServiceTicketsByStatusListAndDateCreatedInDaysV1(payload);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getActiveMachinesByStatus = createAsyncThunk("service/getActiveMachinesByStatus", async (payload, { rejectWithValue }) => {
  try {
    return await Service.getActiveMachinesByStatus(payload);
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const notifyVendor = createAsyncThunk("service/notifyVendor", async (payload, { rejectWithValue }) => {
  try {
    return await Service.notifyVendor(payload);
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getAllServiceTickets = createAsyncThunk("service/getAllServiceTickets", async (payload, { rejectWithValue }) => {
  try {
    return await Service.getAllServiceTickets();
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getOverdueServiceTickets = createAsyncThunk("service/getOverdueServiceTickets", async (payload, { rejectWithValue }) => {
  try {
    return await Service.getOverdueServiceTickets();
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getOutOfServiceMachinesByCategory = createAsyncThunk(
  "service/getOutOfServiceMachinesByCategory",
  async (payload, { rejectWithValue }) => {
    try {
      return await Service.getOutOfServiceMachinesByCategory();
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusList = createAsyncThunk(
  "service/getServiceTicketsByStatusList",
  async (payload, { rejectWithValue }) => {
    try {
      return await Service.getServiceTicketsByStatusList(payload);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusListForSupervisor= createAsyncThunk("serviceapi/getServiceTicketsByStatusListForSupervisor", async (payload, thunkAPI) => {
  try {
    return await Service.getServiceTicketsByStatusListForSupervisor(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const getServiceTicketsByUserId = createAsyncThunk("service/getServiceTicketsByUserId", async (payload, { rejectWithValue }) => {
  try {
    return await Service.getServiceTicketsByUserId(payload);
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const addMachine = createAsyncThunk("service/addMachine", async (payload, thunkAPI) => {
  try {
    return await Service.addMachine(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addSpareParts = createAsyncThunk("service/addSpareParts", async (payload, thunkAPI) => {
  try {
    return await Service.addSpareParts(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSpareParts = createAsyncThunk("service/updateSpareParts", async (payload, thunkAPI) => {
  try {
    return await Service.updateSpareParts(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteSpareParts = createAsyncThunk("service/deleteSpareParts", async (payload, thunkAPI) => {
  try {
    return await Service.deleteSpareParts(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readSpareParts = createAsyncThunk("service/readSpareParts", async (payload, thunkAPI) => {
  try {
    return await Service.readSpareParts();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMachineDepartments = createAsyncThunk("serviceapi/machineDepartments", async (payload, thunkAPI) => {
  try {
    return await Service.getMachineDepartments();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMachinesNotWorking = createAsyncThunk("service/getMachinesNotWorking", async (payload, thunkAPI) => {
  try {
    return await Service.getMachinesNotWorking();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateMachine = createAsyncThunk("service/updateMachine", async (payload, thunkAPI) => {
  try {
    return await Service.updateMachine(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const machineDepartments = createAsyncThunk("serviceapi/machineDepartments", async (payload, thunkAPI) => {
  try {
    return await Service.machineDepartments();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteMachine = createAsyncThunk("service/deleteMachine", async (payload, thunkAPI) => {
  try {
    return await Service.deleteMachine(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const engineerReportsById = createAsyncThunk("service/EngReports", async (payload, thunkAPI) => {
  try {
    return await Service.engineerReportsById(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMonthlyMachineBreakdownTime = createAsyncThunk("service/getMonthlyMachineBreakdownTime", async (payload, thunkAPI) => {
  try {
    return await Service.getMonthlyMachineBreakdownTime();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getWeeklyMachineBreakdownTime = createAsyncThunk("service/getWeeklyMachineBreakdownTime", async (payload, thunkAPI) => {
  try {
    return await Service.getWeeklyMachineBreakdownTime();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMonthlyBreakdownHoursPercent = createAsyncThunk("service/getMonthlyBreakdownHoursPercent", async (payload, thunkAPI) => {
  try {
    return await Service.getMonthlyBreakdownHoursPercent();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getWeeklyBreakdownHoursPercent = createAsyncThunk("service/getWeeklyBreakdownHoursPercent", async (payload, thunkAPI) => {
  try {
    return await Service.getWeeklyBreakdownHoursPercent();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readAsyncStorageValues = createAsyncThunk("service/readAsync", async (user, thunkAPI) => {
  try {
    return await Service.readAsyncStorageValues();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readServiceTicketsByDepartmentAndDateCreatedInDays = createAsyncThunk(
  "service/TicketsByDepartment",
  async (payload, thunkAPI) => {
    try {
      return await Service.getServiceTicketsByDepartmentAndDateCreatedInDays(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const readServiceTicketsByMachineId = createAsyncThunk("service/readServiceTicketsByMachineId", async (payload, thunkAPI) => {
  try {
    return await Service.readServiceTicketsByMachineId(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readTimespentByServicetTicketId = createAsyncThunk("service/TimespentByServicetTicket", async (payload, thunkAPI) => {
  try {
    return await Service.getTimespentByServicetTicketId(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getMachines = createAsyncThunk("service/readMachines", async (user, thunkAPI) => {
  try {
    return await Service.getMachines();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readHoursDataByDays = createAsyncThunk("/serviceapi/getTimespentByDepartment?days=", async (payload, thunkAPI) => {
  try {
    return await Service.readHoursDataByDays(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addImageOrVideoToServiceTicket = createAsyncThunk("serviceapi/addImageOrVideoToServiceTicket", async (payload, thunkAPI) => {
  try {
    return await Service.addImageOrVideoToServiceTicket(payload.formData, payload.progressCallback);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readServiceTicketMessages = createAsyncThunk(
  "serviceapi/readServiceTicketMessagesByServiceTicketId?id=",
  async (payload, thunkAPI) => {
    try {
      return await Service.readServiceTicketMessages(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteImageOrVideoFromServiceTicket = createAsyncThunk(
  "serviceTickets/deleteImageOrVideoFromServiceTicket",
  async (payload, thunkAPI) => {
    try {
      return await Service.deleteImageOrVideoFromServiceTicket(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateNotesForTicket = createAsyncThunk("serviceapi/updateNotesForTicket", async (payload, thunkAPI) => {
  try {
    return await Service.updateNotesForTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readServiceTimeLog = createAsyncThunk("serviceapi/readServiceTimeLog?id=", async (payload, thunkAPI) => {
  try {
    return await Service.readServiceTimeLog(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addTimeLogToServiceTicket = createAsyncThunk("serviceapi/addTimeLogToServiceTicket", async (payload, thunkAPI) => {
  try {
    return await Service.addTimeLogToServiceTicket(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCompletedReportPdf = createAsyncThunk(
  "/serviceapi/readReportPathByServiceTicketIdV1?serviceTicketId=",
  async (payload, thunkAPI) => {
    try {
      return await Service.getCompletedReportPdf(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTemplate = createAsyncThunk("/serviceapi/createTemplate", async (payload, thunkAPI) => {
  try {
    return await Service.createTemplate(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTemplate = createAsyncThunk("/serviceapi/updateTemplate", async (payload, thunkAPI) => {
  try {
    return await Service.updateTemplate(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllTemplates = createAsyncThunk("/serviceapi/getAllTemplates", async (payload, thunkAPI) => {
  try {
    return await Service.getAllTemplates(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const getTechnicianLoggedTime = createAsyncThunk("/serviceapi/getTechnicianLoggedTime", async (payload, thunkAPI) => {
  try {
    return await Service.getTechnicianLoggedTime(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createMachineModel = createAsyncThunk("/serviceapi/createMachineModel", async (payload, thunkAPI) => {
  try {
    return await Service.createMachineModel(payload.modelFormData, payload.progressCallback);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateMachineModel = createAsyncThunk("/serviceapi/updateMachineModel", async (payload, thunkAPI) => {
  try {
    return await Service.updateMachineModel(payload.modelFormData, payload.progressCallback);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const readServiceTicketMessagesByMachineId = createAsyncThunk(
  "serviceapi/readServiceTicketMessagesByMachineId",
  async (payload, thunkAPI) => {
    try {
      return await Service.readServiceTicketMessagesByMachineId(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const rescheduleServiceTickets = createAsyncThunk("/serviceapi/rescheduleServiceTickets", async (payload, thunkAPI) => {
  try {
    return await Service.rescheduleServiceTickets(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteMachineLocation = createAsyncThunk("serviceapi/deleteMachineLocation?id=", async (payload, thunkAPI) => {
  try {
    return await Service.deleteMachineLocation(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatingOrAddingServiceProblemCategory = createAsyncThunk(
  "service/updatingOrAddingServiceProblemCategory",
  async (payload, thunkAPI) => {
    try {
      return await Service.updatingOrAddingServiceProblemCategory(payload);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatingDueDatesByTicketType = createAsyncThunk("service/updatingDueDatesByTicketType", async (payload, thunkAPI) => {
  try {
    return await Service.updatingDueDatesByTicketType(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatingNoOfFollowOnTickets = createAsyncThunk("service/updatingNoOfFollowOnTickets", async (payload, thunkAPI) => {
  try {
    return await Service.updatingNoOfFollowOnTickets(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const completeInternalAudit = createAsyncThunk("/serviceapi/completeInternalAudit", async (payload, thunkAPI) => {
  try {
    return await Service.completeInternalAudit(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTemplates.pending, (state) => {
        state.allTemplates.isLoading = true;
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.allTemplates.isLoading = false;
        state.allTemplates.data = action.payload.data;
      })
      .addCase(getAllTemplates.rejected, (state) => {
        state.allTemplates.isLoading = false;
      })
      .addCase(createTemplate.pending, (state) => {
        state.templateById.isLoading = true;
      })
      .addCase(createTemplate.fulfilled, (state, { payload }) => {
        state.templateById.isLoading = false;
        state.templateById.data = payload.data;
      })
      .addCase(createTemplate.rejected, (state, { payload }) => {
        state.templateById.isLoading = false;
      })
      .addCase(updateTemplate.pending, (state) => {
        state.templateById.isLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state, { payload }) => {
        state.templateById.isLoading = false;
        state.templateById.data = payload.data;
      })
      .addCase(updateTemplate.rejected, (state, { payload }) => {
        state.templateById.isLoading = false;
      })
      .addCase(getServiceTicketsByUserId.pending, (state) => {
        state.serviceTicketsByUserId.isLoading = true;
        state.serviceTicketsByUserId.data = [];
        state.error = null;
      })
      .addCase(getServiceTicketsByUserId.fulfilled, (state, { payload }) => {
        state.serviceTicketsByUserId.isLoading = false;
        state.serviceTicketsByUserId.data = payload;
      })
      .addCase(getServiceTicketsByUserId.rejected, (state, { payload }) => {
        state.serviceTicketsByUserId.isLoading = false;
        state.serviceTicketsByUserId.data = [];
        state.error = payload;
      })
      .addCase(getServiceTicketsByStatusListAndDateCreatedInDaysV1.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getServiceTicketsByStatusListAndDateCreatedInDaysV1.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.serviceTicketsByStatusListAndDateCreatedInDays = payload;
      })
      .addCase(getServiceTicketsByStatusListAndDateCreatedInDaysV1.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(getServiceTicketsByStatusList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getServiceTicketsByStatusList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.serviceTicketsByStatusList = payload;
      })
      .addCase(getServiceTicketsByStatusList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(readServiceTicketsByDepartmentAndDateCreatedInDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readServiceTicketsByDepartmentAndDateCreatedInDays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceTicketsByDepartment = action.payload.data;
      })
      .addCase(readServiceTicketsByDepartmentAndDateCreatedInDays.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceTicketsByDepartment = [];
        state.message = "";
      })
      .addCase(readTimespentByServicetTicketId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readTimespentByServicetTicketId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timespentByServicetTicket = action.payload.data;
      })
      .addCase(readTimespentByServicetTicketId.rejected, (state, action) => {
        state.isLoading = false;
        state.timespentByServicetTicket = [];
        state.message = "";
      })
      .addCase(readHoursDataByDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readHoursDataByDays.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hoursDataByDays = action.payload.data;
      })
      .addCase(readHoursDataByDays.rejected, (state, action) => {
        state.isLoading = false;
        state.hoursDataByDays = [];
      })
      .addCase(engineerReportsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(engineerReportsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.engineerReports = action.payload.data;
        state.message = "";
      })
      .addCase(engineerReportsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.engineerReports = [];
        state.message = "";
      })
      .addCase(readAsyncStorageValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readAsyncStorageValues.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.appConstants = payload;
        state.message = "";
      })
      .addCase(readAsyncStorageValues.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.appConstants = [];
        state.message = "";
      })
      .addCase(getMachines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMachines.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.machinesData = payload;
        state.error = null;
      })
      .addCase(getMachines.rejected, (state, { payload }) => {
        state.isLoading = false;
        // state.isError = true;
        state.machinesData = [];
        state.error = [];
        state.message = "";
      })
      .addCase(getMachineDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMachineDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.machineDepartments = action.payload.data;
      })
      .addCase(getMachineDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.machineDepartments = [];
        state.message = "";
      })
      .addCase(addMachine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMachine.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(addMachine.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(updateMachine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMachine.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(updateMachine.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(readServiceTicketsByMachineId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readServiceTicketsByMachineId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceTicketMachine = action.payload.data;
      })
      .addCase(readServiceTicketsByMachineId.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceTicketMachine = [];
        state.message = "";
      })
      .addCase(deleteMachine.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMachine.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteMachine.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getServiceTicketById.pending, (state) => {
        state.isLoading = true;
        state.serviceTicketById = {};
      })
      .addCase(getServiceTicketById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.serviceTicketById = payload;
      })
      .addCase(getServiceTicketById.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceTicketById = {};
      })
      .addCase(createServiceTicket.pending, (state) => {
        state.createTicket.isLoading = true;
        state.createTicket.data = {};
      })
      .addCase(createServiceTicket.fulfilled, (state, action) => {
        state.createTicket.isLoading = false;
        state.createTicket.data = action.payload.data;
      })
      .addCase(createServiceTicket.rejected, (state, action) => {
        state.createTicket.isLoading = false;
      })
      .addCase(updateServiceTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateServiceTicket.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateServiceTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(approveServiceTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveServiceTicket.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(approveServiceTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(addSpareParts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSpareParts.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(addSpareParts.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(updateSpareParts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSpareParts.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(updateSpareParts.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(deleteSpareParts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSpareParts.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(deleteSpareParts.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(readSpareParts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readSpareParts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spareParts = action.payload.data;
      })
      .addCase(readSpareParts.rejected, (state, action) => {
        state.isLoading = false;
        state.spareParts = [];
        state.message = "";
      })
      .addCase(getMachinesNotWorking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMachinesNotWorking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.machinesNotWorking = action.payload.data;
      })
      .addCase(getMachinesNotWorking.rejected, (state, action) => {
        state.isLoading = false;
        state.machinesNotWorking = [];
        state.message = "";
      })
      .addCase(getCompletedReportPdf.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompletedReportPdf.fulfilled, (state, action) => {
        state.isLoading = false;
        state.completedReportPdf = action.payload.data;
      })
      .addCase(getCompletedReportPdf.rejected, (state, action) => {
        state.isLoading = false;
        state.completedReportPdf = [];
      })
      .addCase(addImageOrVideoToServiceTicket.pending, (state) => {
        state.isUploading = true;
      })
      .addCase(addImageOrVideoToServiceTicket.fulfilled, (state, action) => {
        state.isUploading = false;
      })
      .addCase(addImageOrVideoToServiceTicket.rejected, (state, action) => {
        state.isUploading = false;
        state.message = "";
      })
      .addCase(readServiceTicketMessages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readServiceTicketMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceTicketMessages = action.payload.data;
      })
      .addCase(readServiceTicketMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(readServiceTimeLog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readServiceTimeLog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceTimeLogs = action.payload.data;
      })
      .addCase(readServiceTimeLog.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getActiveMachinesByStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveMachinesByStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeMachinesByStatus = action.payload.data;
      })
      .addCase(getActiveMachinesByStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getOverdueServiceTickets.pending, (state) => {
        state.overdueServiceTickets.isLoading = true;
      })
      .addCase(getOverdueServiceTickets.fulfilled, (state, action) => {
        state.overdueServiceTickets.isLoading = false;
        state.overdueServiceTickets.data = action.payload;
        state.overdueServiceTickets.error = null;
      })
      .addCase(getOverdueServiceTickets.rejected, (state, action) => {
        state.overdueServiceTickets.error = action.payload;
      })
      .addCase(getOutOfServiceMachinesByCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOutOfServiceMachinesByCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.outOfServiceMachinesByCategory = action.payload;
      })
      .addCase(getOutOfServiceMachinesByCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getMonthlyBreakdownHoursPercent.pending, (state) => {
        state.monthlyBreakdownHoursPercent.isLoading = true;
      })
      .addCase(getMonthlyBreakdownHoursPercent.fulfilled, (state, action) => {
        state.monthlyBreakdownHoursPercent.isLoading = false;
        state.monthlyBreakdownHoursPercent.data = action.payload.data;
      })
      .addCase(getMonthlyBreakdownHoursPercent.rejected, (state, action) => {
        state.monthlyBreakdownHoursPercent.isLoading = false;
      })
      .addCase(getWeeklyBreakdownHoursPercent.pending, (state) => {
        state.weeklyBreakdownHoursPercent.isLoading = true;
      })
      .addCase(getWeeklyBreakdownHoursPercent.fulfilled, (state, action) => {
        state.monthlyBreakdownHoursPercent.isLoading = false;
        state.weeklyBreakdownHoursPercent.data = action.payload.data;
      })
      .addCase(getWeeklyBreakdownHoursPercent.rejected, (state, action) => {
        state.weeklyBreakdownHoursPercent.isLoading = false;
      })
      .addCase(getMonthlyMachineBreakdownTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonthlyMachineBreakdownTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.monthlyBreakdown = action.payload.data;
      })
      .addCase(getMonthlyMachineBreakdownTime.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getWeeklyMachineBreakdownTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWeeklyMachineBreakdownTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.weeklyBreakdown = action.payload.data;
      })
      .addCase(getWeeklyMachineBreakdownTime.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(createMachineLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMachineLocation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createMachineLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(updateMachineLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMachineLocation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateMachineLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(getTechnicianLoggedTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTechnicianLoggedTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.technicianLoggedTime = action.payload;
      })
      .addCase(getTechnicianLoggedTime.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(readServiceTicketMessagesByMachineId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readServiceTicketMessagesByMachineId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceTicketMessagesByMachineId = action.payload.data;
      })
      .addCase(readServiceTicketMessagesByMachineId.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceTicketMessagesByMachineId = [];
      })
      .addCase(rescheduleServiceTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rescheduleServiceTickets.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(rescheduleServiceTickets.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(notifyVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(notifyVendor.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(notifyVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(updatingOrAddingServiceProblemCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatingOrAddingServiceProblemCategory.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatingOrAddingServiceProblemCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(updatingDueDatesByTicketType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatingDueDatesByTicketType.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatingDueDatesByTicketType.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })
      .addCase(updatingNoOfFollowOnTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatingNoOfFollowOnTickets.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatingNoOfFollowOnTickets.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })

      .addCase(deleteMachineLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteMachineLocation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteMachineLocation.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(getSparesPartsFromServiceTicket.pending, (state) => {
        state.serviceTicketSpareParts.isLoading = true;
        state.error = null;
      })
      .addCase(getSparesPartsFromServiceTicket.fulfilled, (state, { payload }) => {
        state.serviceTicketSpareParts.isLoading = false;
        state.serviceTicketSpareParts.data = payload;
      })
      .addCase(getSparesPartsFromServiceTicket.rejected, (state, { payload }) => {
        state.serviceTicketSpareParts.isLoading = false;
        state.error = payload;
      })
      .addCase(getAllServiceTickets.pending, (state) => {
        state.allServiceTickets.isLoading = true;
      })
      .addCase(getAllServiceTickets.fulfilled, (state, action) => {
        state.allServiceTickets.isLoading = false;
        state.allServiceTickets.data = action.payload.data;
      })
      .addCase(getAllServiceTickets.rejected, (state, action) => {
        state.allServiceTickets.isLoading = false;
        state.allServiceTickets.data = [];
      })
      .addCase(completeInternalAudit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeInternalAudit.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(completeInternalAudit.rejected, (state, action) => {
        state.isLoading = false;
        state.message = "";
      })

      .addCase(getServiceTicketsStatusByTechId.pending, (state) => {
        state.serviceTicketsStatusByTech.isLoading = true;
      })
      .addCase(getServiceTicketsStatusByTechId.fulfilled, (state, { payload }) => {
        state.serviceTicketsStatusByTech.isLoading = false;
        state.serviceTicketsStatusByTech.data = payload;
      })
      .addCase(getServiceTicketsStatusByTechId.rejected, (state) => {
        state.serviceTicketsStatusByTech.isLoading = false;
        state.serviceTicketsStatusByTech.data = [];
      })
      .addCase(getServiceTicketsByStatusListForSupervisor.pending, (state) => {
        state.serviceTicketsStatusForSupervisor.isLoading = true;
      })
      .addCase(getServiceTicketsByStatusListForSupervisor.fulfilled, (state, { payload }) => {
        state.serviceTicketsStatusForSupervisor.isLoading = false;
        state.serviceTicketsStatusForSupervisor.data = payload;
      })
      .addCase(getServiceTicketsByStatusListForSupervisor.rejected, (state) => {
        state.serviceTicketsStatusForSupervisor.isLoading = false;
        state.serviceTicketsStatusForSupervisor.data = [];
      });
  },
});

export const { reset } = serviceSlice.actions;
// export const selectAssignedTicket = (state) => state.service.data

export default serviceSlice.reducer;
